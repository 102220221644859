<template>
  <sign-page
    title-text="请假历史记录"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :data-file-parm="{pDirPath: '/ask_leave/'}"
    :title-menus="[]"
    :tableActions="tableActions"
    :dealSearchParm="dealSearchParm"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request
} from '@/api'

export default {
  methods: {
    dealSearchParm () {
      return {
        status: '审批通过'
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'data_file',
        label: '附件'
      }]
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        fixed: 'left',
        field: 'id'
      },
      {
        title: '员工姓名',
        sort: true,
        fixed: 'left',
        field: 'workerName'
      },
      {
        title: '员工工号',
        sort: true,
        fixed: 'left',
        field: 'workerCode'
      },
      {
        title: '请假类型',
        sort: true,
        fixed: 'left',
        field: 'type'
      },
      {
        title: '申请时间',
        sort: true,
        width: 130,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        sort: true,
        width: 130,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        sort: true,
        width: 130,
        field: 'endTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        width: 100,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson'
      },
      {
        title: '审批人',
        sort: true,
        fixed: 'right',
        field: 'spWorkerName'
      },
      {
        title: '状态',
        fixed: 'right',
        sort: true,
        field: 'status'
      }]
      return data
    }
  },
  data () {
    return {
      request
    }
  }
}
</script>
